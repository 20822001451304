var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass:
        "flex justify-center flex-col items-center text-gray-800 font-semibold relative w-full",
      attrs: { id: "form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updateClinic.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "w-1/2 mb-16" }, [
        _c("div", { staticClass: "bg-white p-10" }, [
          _c("p", { staticClass: "text-xl font-black text-left" }, [
            _vm._v(" Clinic settings "),
          ]),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("base-label", { attrs: { for: "name" } }, [
                _vm._v(" Display name "),
              ]),
              _c("secondary-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min:3",
                    expression: "'min:3'",
                  },
                ],
                attrs: {
                  id: "name",
                  placeholder: "Ex. Clinic Name",
                  name: "name",
                  type: "text",
                  "data-vv-as": "first name",
                },
                model: {
                  value: _vm.digitalClinic.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.digitalClinic, "name", $$v)
                  },
                  expression: "digitalClinic.name",
                },
              }),
              _c("p", { staticClass: "text-red mt-2 text-sm" }, [
                _vm._v(" " + _vm._s(_vm.errors.first("firstName")) + " "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("base-label", { attrs: { for: "slug" } }, [
                _vm._v(" URL slug "),
                _c("p", { staticClass: "text-xs mt-1" }, [
                  _vm._v(
                    ' For example if display name is "Clinic name" this could be "clinic-name" '
                  ),
                ]),
              ]),
              _c("secondary-input", {
                attrs: {
                  id: "slug",
                  placeholder: "Ex. clinic name",
                  name: "slug",
                  type: "text",
                  "data-vv-as": "url slug",
                },
                model: {
                  value: _vm.digitalClinic.slug,
                  callback: function ($$v) {
                    _vm.$set(_vm.digitalClinic, "slug", $$v)
                  },
                  expression: "digitalClinic.slug",
                },
              }),
              _c("p", { staticClass: "text-red mt-2 text-sm" }, [
                _vm._v(" " + _vm._s(_vm.errors.first("slug")) + " "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("base-label", { attrs: { for: "country" } }, [
                _vm._v(" Country "),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.digitalClinic.country_id,
                      expression: "digitalClinic.country_id",
                    },
                  ],
                  staticClass: "h-12 block mt-2 w-full text-sm border",
                  attrs: { id: "country" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.digitalClinic,
                        "country_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "" }, domProps: { value: null } },
                    [_vm._v(" Select a country ")]
                  ),
                  _vm._l(_vm.countries, function (c) {
                    return _c(
                      "option",
                      { key: c.id, domProps: { value: c.id } },
                      [_vm._v(" " + _vm._s(c.name) + " ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("base-label", { attrs: { for: "email" } }, [
                _vm._v(" Reply-to email "),
                _c("p", { staticClass: "text-xs mt-1" }, [
                  _vm._v(
                    " Email address used for all emails sent from the platform "
                  ),
                ]),
              ]),
              _c("secondary-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "email",
                    expression: "'email'",
                  },
                ],
                attrs: {
                  id: "email",
                  placeholder: "Ex. mail@mail.com",
                  name: "email",
                  type: "email",
                },
                model: {
                  value: _vm.digitalClinic.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.digitalClinic, "email", $$v)
                  },
                  expression: "digitalClinic.email",
                },
              }),
              _c("p", { staticClass: "text-red mt-2 text-sm" }, [
                _vm._v(" " + _vm._s(_vm.errors.first("email")) + " "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("base-label", { attrs: { for: "description" } }, [
                _vm._v(" Description "),
                _c("p", { staticClass: "text-xs mt-1" }, [
                  _vm._v(" Displayed on customer login page "),
                ]),
              ]),
              _c("textarea-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min:4",
                    expression: "'min:4'",
                  },
                ],
                staticClass: "text-base",
                attrs: {
                  id: "description",
                  rows: 6,
                  placeholder: "Description",
                  name: "description",
                },
                model: {
                  value: _vm.digitalClinic.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.digitalClinic, "description", $$v)
                  },
                  expression: "digitalClinic.description",
                },
              }),
              _vm.errors.first("description")
                ? _c("p", { staticClass: "text-red mt-2 text-sm" }, [
                    _vm._v(" " + _vm._s(_vm.errors.first("description")) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.errorMessages && !_vm.pricingErrors
            ? _c(
                "div",
                {
                  staticClass:
                    "flex flex-row my-4 p-2 bg-white border border-red-500 rounded",
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-4 ml-2 text-lg self-center text-red",
                    attrs: { icon: "exclamation-circle" },
                  }),
                  _c(
                    "div",
                    _vm._l(_vm.errorMessages, function (error, index) {
                      return _c(
                        "p",
                        { key: index, staticClass: "self-center text-base" },
                        _vm._l(error, function (e, i) {
                          return _c("span", { key: i }, [_vm._v(_vm._s(e))])
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "bg-white mt-8 p-10" }, [
          _c("p", { staticClass: "text-xl font-black text-left" }, [
            _vm._v(" Logotype "),
          ]),
          _c("div", { staticClass: "mt-8" }, [
            _vm.digitalClinic.logo
              ? _c("div", [
                  _c("p", [_vm._v("Current logotype")]),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between mt-4" },
                    [
                      _c("div", { staticClass: "flex-1 max-w-xs mx-auto" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.digitalClinicLogoSrc,
                            alt: _vm.digitalClinic.name + " logotype",
                          },
                        }),
                      ]),
                      _c(
                        "base-button",
                        {
                          attrs: {
                            color: "danger",
                            "aria-label": "Remove logotype",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeFile()
                            },
                          },
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "trash" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mt-8" },
              [
                _c("base-label", { attrs: { for: "logo" } }, [
                  _vm._v(" Upload logotype "),
                  _c("p", { staticClass: "text-xs mt-1" }, [
                    _vm._v(" PNG, max file size 1Mb "),
                  ]),
                ]),
                _vm.logo.name
                  ? _c(
                      "p",
                      { staticClass: "mb-4 mt-2 flex items-center" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "image" },
                        }),
                        _vm._v(" " + _vm._s(_vm.logo.name) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("label", { staticClass: "file w-full text-base" }, [
                  _c("input", {
                    attrs: {
                      id: "logo",
                      type: "file",
                      "aria-label": "Upload logo",
                      accept: "image/png",
                    },
                    on: { change: _vm.uploadFile },
                  }),
                  _c("span", {
                    staticClass: "file-custom text-gray-700 rounded",
                  }),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "bg-white mt-8 p-10 mb-10" }, [
          _c("p", { staticClass: "text-xl font-black text-left" }, [
            _vm._v(" Pricing "),
          ]),
          _c("div", { staticClass: "mt-8" }, [
            _c("p", { staticClass: "font-black" }, [
              _vm._v(" Weekday working hours "),
            ]),
            _c("div", { staticClass: "flex mt-3" }, [
              _c(
                "div",
                { staticClass: "mr-5 w-1/4" },
                [
                  _c("base-label", { attrs: { for: "start" } }, [
                    _vm._v(" Start "),
                  ]),
                  _c("secondary-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "date_format:HH:mm",
                        expression: "'date_format:HH:mm'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      id: "start",
                      type: "time",
                      name: "start",
                      placeholder: "07:00",
                      "data-vv-as": "start time",
                    },
                    model: {
                      value: _vm.digitalClinic.work_day_start,
                      callback: function ($$v) {
                        _vm.$set(_vm.digitalClinic, "work_day_start", $$v)
                      },
                      expression: "digitalClinic.work_day_start",
                    },
                  }),
                  _c("p", { staticClass: "text-red mt-2 text-sm" }, [
                    _vm._v(" " + _vm._s(_vm.errors.first("start")) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mr-5 w-1/4" },
                [
                  _c("base-label", { attrs: { for: "end" } }, [
                    _vm._v(" End "),
                  ]),
                  _c("secondary-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "date_format:HH:mm",
                        expression: "'date_format:HH:mm'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      id: "end",
                      type: "time",
                      name: "end",
                      placeholder: "17:00",
                      "data-vv-as": "end time",
                    },
                    model: {
                      value: _vm.digitalClinic.work_day_end,
                      callback: function ($$v) {
                        _vm.$set(_vm.digitalClinic, "work_day_end", $$v)
                      },
                      expression: "digitalClinic.work_day_end",
                    },
                  }),
                  _c("p", { staticClass: "text-red mt-2 text-sm" }, [
                    _vm._v(" " + _vm._s(_vm.errors.first("end")) + " "),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "mt-8" }, [
            _c("p", { staticClass: "font-black" }, [_vm._v(" Pricing ")]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("base-label", { attrs: { for: "base" } }, [
                  _vm._v(" Base price (weekday workday hours) "),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-1/4" },
                    [
                      _c("secondary-input", {
                        attrs: {
                          id: "base",
                          placeholder: "30",
                          name: "base",
                          type: "number",
                        },
                        model: {
                          value: _vm.digitalClinic.price_normal,
                          callback: function ($$v) {
                            _vm.$set(_vm.digitalClinic, "price_normal", $$v)
                          },
                          expression: "digitalClinic.price_normal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "ml-4 text-gray-500" }, [
                    _vm._v(" " + _vm._s(_vm.currency) + " "),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("base-label", { attrs: { for: "inconvenient" } }, [
                  _vm._v(" Weekday inconvenient hours price "),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-1/4" },
                    [
                      _c("secondary-input", {
                        attrs: {
                          id: "inconvenient",
                          placeholder: "30",
                          name: "inconvenient",
                          type: "number",
                        },
                        model: {
                          value: _vm.digitalClinic.price_inconvenient,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.digitalClinic,
                              "price_inconvenient",
                              $$v
                            )
                          },
                          expression: "digitalClinic.price_inconvenient",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "ml-4 text-gray-500" }, [
                    _vm._v(" " + _vm._s(_vm.currency) + " "),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("base-label", { attrs: { for: "holiday" } }, [
                  _vm._v(" Weekend and holiday price "),
                ]),
                _c("div", { staticClass: "flex items-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-1/4" },
                    [
                      _c("secondary-input", {
                        attrs: {
                          id: "holiday",
                          placeholder: "30",
                          name: "holiday",
                          type: "number",
                        },
                        model: {
                          value: _vm.digitalClinic.price_holiday,
                          callback: function ($$v) {
                            _vm.$set(_vm.digitalClinic, "price_holiday", $$v)
                          },
                          expression: "digitalClinic.price_holiday",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "ml-4 text-gray-500" }, [
                    _vm._v(" " + _vm._s(_vm.currency) + " "),
                  ]),
                ]),
              ],
              1
            ),
            _vm.errorMessages && _vm.pricingErrors
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row my-4 p-2 bg-white border border-red-500 rounded",
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-4 ml-2 text-lg self-center text-red",
                      attrs: { icon: "exclamation-circle" },
                    }),
                    _c(
                      "div",
                      _vm._l(_vm.errorMessages, function (error, index) {
                        return _c(
                          "p",
                          { key: index, staticClass: "self-center text-base" },
                          _vm._l(error, function (e, i) {
                            return _c("span", { key: i }, [_vm._v(_vm._s(e))])
                          }),
                          0
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "fixed flex justify-center bg-background-gray border-t border-gray-500 rounded-md text-lg w-full py-5 z-10",
          staticStyle: { bottom: "0", height: "90px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "w-1/2 relative",
              style: _vm.showTimeline
                ? "margin-right: 8rem;"
                : "margin-right: 3rem;",
            },
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "absolute right-0",
                  attrs: { color: "primary", disabled: _vm.loading },
                },
                [
                  _vm._v(" Update "),
                  _vm.loading ? _c("span", [_c("base-spinner")], 1) : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }