<template>
  <div class="w-full">
    <settings-form />
  </div>
</template>

<script>
import SettingsForm from '@/components/admin/settings/SettingsForm';

export default {
  components: {
    SettingsForm,
  },
};
</script>
